@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/
  /*
  共通
  -------------------------------------*/
  form{
    margin-top:90px;
    dl{
      width:100%;
      display: table;
      border-bottom:1px solid #efefef;
      padding:20px 0;
      table-layout: fixed;
      &:first-of-type{
        border-top:1px solid #efefef;
      }
      dt,dd{
        display:table-cell;
        vertical-align:top;
        font-size:16px;
      }
      dt{
        width:260px;
        padding-left:27px;
        padding-top:12px;
      }
      dd{
        &:first-child{
          padding-top:17px;
          width:32px;
          text-align:center;
          span{
            display:block;
            width:32px;
            height:16px;
            line-height:16px;
            font-size: 12px;
            color:#fff;
            text-align: center;
            background:#8f8f8f;
            &.required{
              background:#be4242;
            }
          }
        }
        &:last-child{
          input,textarea{
            width:210px;
            height:50px;
            border:1px solid #979797;
            background-color:#f4f4f4;
            padding-left:18px;
            border-radius: 0!important;
            &::placeholder {
              color: #b0b0b0;
            }
            &:-ms-input-placeholder {
              color: #b0b0b0;
            }
            &::-ms-input-placeholder {
              color: #b0b0b0;
            }
            &.middle{
              width:320px;
            }
            &.long{
              width:400px;
            }
            &:focus{
              box-shadow:0px 0px 0px 2px #64b9fa;
              background-color:#fff;
            }
          }
          textarea{
            width:100%;
            height:250px;
            border:1px solid #979797;
            background-color:#f4f4f4;
            padding:14px 18px 0;
            resize: none;
          }
        }
      }
    }
    ul{
      position: relative;
      width:100%;
      margin:60px 0 0;
      li{
        &.reset{
          position:absolute;
          top:15px;
          left:0;
          input[type="button"]{
            opacity: 1;
            color: #000;
            font-size: 14px;
            text-decoration: underline;
            &:hover{
              text-decoration:none;
            }
          }
        }
        #image-btn{
          display: block;
          width: 280px;
          height: 60px;
          margin: 0px auto;
          background-color: #0053ad;
          opacity: 1;
          color: #fff;
          font-size: 20px;
          text-decoration: none;
          text-align:center;
          border-radius: 0!important;
          &:hover{
            opacity:0.7;
          }
        }
      }
    }
  }
  .pageConfirm form{
    margin-top: 54px;
    dl{
      padding:30px 0;
      dt{
        padding-top:3px;
      }
      dd{
        &:first-of-type{
          padding-top:6px;
        }
        &:last-of-type{
          line-height:28px;
        }
      }
    }
  }
  .pageThanks{
    .telBox{
      text-align:center;
      margin-top:10px;
      dt,dd{
        display:inline-block;
        vertical-align:middle;
      }
      dd{
        font-size:14px;
      }
    }
    .linkBtn{
      width:280px;
      height:60px;
      line-height:60px;
      margin:50px auto 0;
      a{
        display:block;
        width:100%;
        height:100%;
        background-color:#e8e8e8;
        text-align:center;
        font-size:20px;
        color:#8d8d8d;
        text-decoration:none;
      }
    }
  }
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    padding-top:0;
    img{
      width:100%;
      height:auto;
    }
    #Header{
      #ContBoxHeader{
        background-color:transparent;
        #HeaderMenu{
          ul{
            li{
              text-shadow:0px 0px 20px rgba(0,0,0,0.4);
            }
          }
        }
      }
    }
    &.fixed{
      #Header{
        #ContBoxHeader{
          background-color: #141414;
        }
      }
    }
    #MainImg{
      img {
        visibility: hidden;
        width: 100%;
      }
      position:relative;
      min-width:1000px;
      overflow:hidden;
      #MainImgInner{
        font-family: 'Barlow Condensed', sans-serif;
        font-weight: 500;
        color:#fff;
        ul.slider li{
          z-index: 10;
          opacity: 0;
          position: absolute;
          top: 0;
          left:0;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          &:nth-of-type(1) {
            width: 105%;
            height: 100%;
            background-image:url(../img/contents/visu1.jpg);
            -webkit-animation: anime 12000ms 0s infinite;
            animation: anime 12000ms 0s infinite;
          }
          &:nth-of-type(2) {
            width: 120%;
            height: 100%;
            background-image:url(../img/contents/visu2.jpg);
             -webkit-animation: anime02 12000ms 0s infinite;
             animation: anime02 12000ms 0s infinite;
             -webkit-animation-delay: 4000ms;
             animation-delay: 4000ms
          }
          &:nth-of-type(3) {
            top:-150px;
            width: 100%;
            height: 123%;
            background-image:url(../img/contents/visu3.jpg);
            -webkit-animation: anime03 12000ms 0s infinite;
            animation: anime03 12000ms 0s infinite;
            -webkit-animation-delay: 8000ms;
            animation-delay: 8000ms;
          }

          img{
            width:100%;
            visibility:hidden;
          }
        }
        .txt{
          text-align:left;
          position:absolute;
          top:50%;
          left:10%;
          transform: translateY(-50%);
          z-index:100;
          h2{
            font-size:100px;
            display:inline-block;
            border-bottom:4px solid #fff;
            line-height:1;
            text-shadow:0px 0px 50px rgba(0,0,0,0.4);
            padding-bottom:15px;
            margin-bottom:25px;
            letter-spacing: 6px;
          }
          ul{
            padding-left: 10px;
            li{
              text-shadow:0px 0px 30px rgba(0,0,0,0.5);
              font-size:50px;
              line-height:1;
              letter-spacing: 2.5px;
              &+li{
                margin-top:15px;
              }
            }
          }
        }
        .scroll{
          text-shadow:0px 0px 20px rgba(0,0,0,0.5);
          position:absolute;
          bottom:0;
          right:4%;
          font-size:18px;
          z-index:100;
          span{
            writing-mode: vertical-rl;
            padding-bottom: 60px;
            letter-spacing: 4px;
            &:after{
              content:"";
              display:block;
              width:1px;
              height:60px;
              background-color:#dedede;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }
          }
        }
      }
    }
    #Main{
      .contBox{
        h3{
          text-align:center;
          span{
            font-family: 'Barlow Condensed', sans-serif;
            font-weight: 500;
            font-size:50px;
            letter-spacing: 2px;
          }
        }
      }
      #ContBox01{
        padding:160px 0 70px;
        margin-top:-100px;
        position:relative;
        z-index:5000;
        background:url(../img/contents/top_illust01.png) no-repeat top left,
        url(../img/contents/top_illust02.png) no-repeat bottom 60px right;
        background-size:426px auto,248px auto;
        h3{
          margin-bottom:35px;
        }
        p{
          line-height:34px;
          text-align:center;
          &+p{
            margin-top:15px;
          }
        }
      }
      #ContBox02{
        ul{
          li{
            width:50%;
            float:left;
            a{
              display:block;
              width:100%;
              height:100%;
              position:relative;
              text-decoration:none;
              dl{
                dd{
                  overflow:hidden;
                  img{
                    transition: all .5s ease-in-out 0s;
                  }
                }
                dt{
                  position:absolute;
                  transform: translateY(-50%);
                  top:50%;
                  left:0;
                  width:100%;
                  font-size:22px;
                  color:#fff;
                  padding-right:58px;
                  text-align:right;
                }
              }
              &:hover{
                opacity:1;
                dl{
                  dd{
                    img{
                      transform: scale(1.05);
                    }
                  }
                }
              }
            }
          }
        }
      }
      #ContBox03{
        h3{
          text-align:left;
          line-height: 1;
          margin-bottom: 20px;
        }
        .txt{
          width:440px;
          P{
            &+p{
              margin-top:10px;
            }
          }
          a{
            margin-top:34px;
          }
        }
      }
      #ContBox04{
        background-color:#212121;
        padding:60px 0;
        .innerBasic{
          display:table;
          table-layout: fixed;
          div{
            width:333px;
            display:table-cell;
            vertical-align:middle;
            h3{
              font-size:30px;
              color:#fff;
              margin-bottom:20px;
              line-height:1;
              text-align:left;
              span{
                font-size:14px;
                font-weight: 100;
                display:block;
                margin-top:5px;
                letter-spacing: 1px;
              }
            }
            ul{
              li{
                font-size:16px;
                background:url(../img/img_arrow04.png) no-repeat center left;
                background-size:6px auto;
                padding-left:12px;
                a{
                  color:#fff;
                  font-family: 'Barlow Condensed', sans-serif;
                  font-weight: 500;
                  text-decoration:none;
                }
              }
            }
          }
          >ul{
            width:667px;
            display:table-cell;
            vertical-align:middle;
            font-size:0;
            letter-spacing:normal;
            li{
              width:50%;
              display:inline-block;
              vertical-align:top;
              border-bottom:1px solid #212121;
              &:nth-child(odd){
                border-right:1px solid #212121;
              }
              &:last-child,&:nth-last-child(2):nth-child(odd){
                border-bottom:none;
              }
              a{
                background-color:#000;
                color:#fff;
                text-decoration:none;
                font-size:16px;
                display:block;
                padding:18px 55px 16px 15px;
                background-image:url(../img/img_arrow02.png);
                background-repeat:no-repeat;
                background-size:26px auto;
                background-position:center right 15px;
              }
            }
          }
        }
      }
      #ContBox07{
        border-top:50px solid #0053ad;
        .imgLink{
          .txt{
            position: absolute;
            top: 50%;
            left: 4.5%;
            transform: translateY(-50%);
            h3{
              line-height:1;
              span{
                font-size:80px;
                color:#0053ad;
              }
            }
            a{
              margin-top:30px;
              border:1px solid #809cbb;
              color:#0053ad;
            }
          }
          &:hover{
            .txt{
              a{
                border-color: #0053ad;
                background-color:#0053ad;
                color:#fff;
              }
            }
          }
        }
      }
    }

@keyframes anime {
  0% {
        opacity: 0;
    }
    17% {
        opacity: 1;
    }
    33% {
        opacity: 1;
    }
    50% {
        opacity: 0;
       transform: scale(1.1) ;
         z-index:9;
    }
    100% { opacity: 0 }
}

@keyframes anime02 {
  0% {
        opacity: 0;
    }
    17% {
        opacity: 1;
    }
    33% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    transform: translate(-60px, 0px);
         z-index:9;
    }
    100% { opacity: 0 }
}

@keyframes anime03 {
  0% {
        opacity: 0;
    }
    17% {
        opacity: 1;
    }
    33% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    transform: translate(0px, 30px);
         z-index:9;
    }
    100% { opacity: 0 }
}


    @keyframes ImgAnime {
      0% {
          opacity: 1;
          //animation-timing-function: ease-in;
      }
      20% {
          opacity: 1;
          transform: scale(1.05);
          //animation-timing-function: ease-out;
      }
      40% {
          opacity: 1;
          transform: scale(1.1);
      }
      60% {
          opacity: 1;
          transform: scale(1.15);
      }
      80% {
          opacity: 1;
          transform: scale(1.2);
      }
      100% { opacity: 1;
        transform: scale(1.25);
       }
    }
    @keyframes ImgAnime02 {
      0% {
          //animation-timing-function: ease-in;
      }
      20% {
          transform: translateX(-3%);
          //animation-timing-function: ease-out;
      }
      40% {
          transform: translateX(-6%);
          //animation-timing-function: ease-out;
      }
      60% {
          transform: translateX(-9%);
      }
      80% {
        transform: translateX(-12%);
      }
      100% {
        transform: translateX(-15%);
       }
    }
    @keyframes ImgAnime03 {
      0% {
          //animation-timing-function: ease-in;
      }
      20% {
          transform: translateY(3%);
          //animation-timing-function: ease-out;
      }
      40% {
          transform: translateY(6%);
          //animation-timing-function: ease-out;
      }
      60% {
          transform: translateY(9%);
      }
      80% {
        transform: translateY(12%);
      }
      100% {
        transform: translateY(15%);
       }
    }
  }

  /*
  当社の強み
  -------------------------------------*/
  #PageAdvantage.pageIndex {
    #MainImg{
      height:740px;
      background:url(../img/contents/tsuyomi_tsuyomi.jpg) no-repeat center center;
      background-size:cover;
      #MainImgInner{
        padding-top:86px;
        h2{
          text-align:center;
          color:#fff;
          font-size:16px;
          letter-spacing: 4px;
          span{
            display:block;
            line-height:1;
            margin-bottom:8px;
            font-family: 'Barlow Condensed', sans-serif;
            font-weight: 500;
            font-size:80px;
            letter-spacing: normal;
          }
        }
        dl{
          text-align:center;
          dt{
            color:#0060c7;
            font-size:36px;
            font-weight:700;
            margin:88px 0 30px;
            letter-spacing: 2px;
          }
          dd{
            color:#fff;
            font-size:16px;
            line-height:34px;
            &+dd{
              margin-top:15px;
            }
          }
        }
      }
    }
    #Main{
      .contBox{
        height:740px;
        background-repeat:no-repeat;
        background-size:cover;
        background-position:center center;
        h3{
          margin-top:-20px;
          font-size:36px;
          font-weight:700;
          line-height:48px;
          color:#004ea2;
          margin-bottom:17px;
          letter-spacing: 1.5px;
          span{
            display:block;
          }
          span:first-of-type{
            display:inline-block;
            line-height:1;
            margin-bottom:13px;
            font-family: 'Barlow Condensed', sans-serif;
            font-weight: 500;
            font-size:14px;
            border-bottom:2px solid #004ea2;
            padding-bottom:6px;
            letter-spacing: 0.2px;
          }
        }
        p{
          &+p{
            margin-top:10px;
          }
        }
        .innerBasic{
          height:100%;
          position:relative;
          >div{
            width:440px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            div{
              a{
                margin-top:30px;
                display:block;
                width:330px;
                height:60px;
                line-height:60px;
                background-color:#000;
                font-size:16px;
                color:#fff;
                background-image:url(../img/img_arrow02.png);
                background-repeat:no-repeat;
                background-position:center right 20px;
                background-size:26px auto;
                text-decoration:none;
                padding-left:18px;
              }
            }
          }
        }
      }
      #ContBox01{
        background-image:url(../img/contents/tsuyomi_feature1.jpg);
      }
      #ContBox02{
        background-image:url(../img/contents/tsuyomi_feature2.jpg);
        .innerBasic{
          >div{
            right:0;
            left:auto;
          }
        }
      }
      #ContBox03{
        background-image:url(../img/contents/tsuyomi_feature3.jpg);
      }
    }
  }

  /*
  事業内容
  -------------------------------------*/
  #PageBusiness.pageIndex {
    img{
      width:100%;
      height:auto;
    }
    #Container{
      background-color:#f1f1f1;
    }
    #Main{
      #ContBox01{
        >.innerBasic{
          >dl{
            >dt{
              color:#004ea2;
              font-size:32px;
              font-weight:700;
              margin-bottom:30px;
              text-align:center;
            }
            >dd{
              background-color:#fff;
              padding:60px 0;
              dl{
                float:left;
                width:334px;
                padding-bottom:15px;
                &+dl{
                  border-left:1px solid #7fa6d0;
                  width:333px;
                }
                dt{
                  color:#004ea2;
                  font-size:20px;
                  font-family: 'Barlow Condensed', sans-serif;
                  font-weight: 500;
                  line-height:1;
                  text-align:center;
                  margin-bottom:18px;
                  margin-top: -5px;
                }
                dd{
                  font-size:20px;
                  line-height:32px;
                  text-align:center;
                  font-weight:700;
                  display:table;
                  width:100%;
                  span{
                    display:table-cell;
                    vertical-align:middle;
                  }
                }
              }
            }
          }
          padding-bottom:125px;
        }
      }
    }
  }

  /*
  製品事例 共通
  -------------------------------------*/
  #PageService{

    #Container{
      border-bottom:1px solid #e3e3e3;
    }
    #Main{
      width:740px;
      float:right;
      margin-top:135px;
      h3{
        font-size:36px;
        font-weight:700;
      }
      .blogList{
        font-size:0;
        letter-spacing:normal;
        margin-bottom:100px;
        li{
          width:220px;
          display:inline-block;
          vertical-align:top;
          margin-right: 40px;
          margin-top:50px;
          &:nth-child(3n){
            margin-right:0;
          }
          dl{
            text-align:center;
            dt{
              font-size:18px;
              line-height:24px;
              font-weight:700;
              text-align:center;
              padding:0 8px;
              margin:13px 0 8px;
              a{
                color:#000;
                text-decoration:none;
              }
            }
            dd{
              &.img{
                width:100%;
                height:220px;
                position: relative;
                overflow: hidden;
                img{
                  width: auto;
                  height: 100%;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
              &.cat{
                background-color:#ececec;
                border:1px solid #dedede;
                font-size:12px;
                color:#777;
                padding:1px 6px;
                display:inline-block;
              }
            }
          }
          &:hover{
            opacity:0.7;
          }
        }
      }
      .blogEntry{
        margin-top:50px;
        figure{
          width:100%;
          margin-bottom:20px;
          img{
            max-width:100%;
            height:auto;
          }
        }
        dl{
          display:table;
          width:100%;
          table-layout: fixed;
          padding:20px 0 20px;
          &+dl{
            border-top:1px solid #dbdbdb;
          }
          dt,dd{
            font-size:16px;
            line-height:28px;
            display:table-cell;
            vertical-align:top;
          }
          dt{
            width:100px;
          }
        }
      }
    }
    #BlogSide {
      ul{
        li{
          &.currnet {
            a {color: #004ea2;}
            &:after{
              background-color: #004ea2;
            }
          }
        }
      }
    }

  }
  /*
  製品事例（カテゴリ）
  -------------------------------------*/
  #PageService.pageIndex {
    #Main {
      #ContBox01 {
        margin-bottom: 218px;
        h3 {
          margin-bottom: 40px;
          line-height: 1.4;
        }
        p {
          margin-bottom: 12px;
        }
        img {
          margin:20px 0 30px 0;
        }
      }
    }
  }

  /*
  /*
  製品事例（カテゴリ）
  -------------------------------------*/
  #PageService.pageCategory {
  }

  /*
  製品事例（詳細）
  -------------------------------------*/
  #PageService.pageEntry {
  }

  /*
  技術事例 共通
  -------------------------------------*/
  #PageTechnology{
    #MainTilte{
      background-color:#261c62;
      #MainTilteInner{
        p{
          color:#8975ff;
        }
      }
    }
  }
  /*
  技術事例
  -------------------------------------*/
  #PageTechnology.pageIndex {
    #Main{
      margin-top:136px;
      .linkTxt{
        text-align:center;
        margin-bottom:95px;
        li{
          display:inline-block;
          font-size:16px;
          a{
            text-decoration:none;
            span{
              font-size:18px;
              color:#261c62;
              font-family: 'Barlow Condensed', sans-serif;
              font-weight: 500;
              padding-right:6px;
            }
          }
          &+li{
            margin-left:78px;
          }
        }
      }
      .contSubBox{
        background-color:#f1f1f1;
        &:before{
          content:"";
          display:block;
          width:100%;
          height:300px;
          background-image:url(../img/contents/gijyutsu_gijyutsu.jpg);
          background-repeat:no-repeat;
          background-size:cover;
          background-position:center center;
        }
        h4{
          padding-top:40px;
          font-size:30px;
          text-align:center;
          letter-spacing: 1.5px;
          span{
            color:#261c62;
            font-size:36px;
            font-family: 'Barlow Condensed', sans-serif;
            font-weight: 500;
            padding-right: 8px;
          }
        }
        ul{
          width:1000px;
          margin:0 auto;
          padding:40px 0 80px;
          li{
            border-bottom:1px solid #d5d5d5;
            width:470px;
            float:left;
            font-size:16px;
            line-height:28px;
            &:nth-child(2n){
              margin-left:60px;
            }
            &:first-child,&:nth-child(2){
              border-top:1px solid #d5d5d5;
            }
            a{
              display:block;
              padding:21px 55px 18px 0;
              background-image:url(../img/img_arrow07.png);
              background-repeat:no-repeat;
              background-size:26px auto;
              background-position:center right;
              text-decoration:none;
            }
          }
        }
      }
      #ContSubBox02{
        &:before{
          background-image:url(../img/contents/gijyutsu_buhin.jpg);
        }
      }
      #ContSubBox03{
        &:before{
          background-image:url(../img/contents/gijyutsu_kanagata.jpg);
        }
      }
      #ContSubBox04{
        &:before{
          background-image:url(../img/contents/gijyutsu_syasyutsu.jpg);
        }
      }
    }
  }

  /*
  技術事例（詳細）
  -------------------------------------*/
  #PageTechnology.pageEntry {
    #Container{
      border-bottom:1px solid #e3e3e3;
    }
    #Main{
      width:740px;
      float:right;
      margin-top: 135px;
      h3{
        font-size:36px;
        line-height:50px;
        font-weight:700;
        margin-bottom:50px;
      }
      .blogEntry{
        margin-bottom:100px;
        .before,.after{
          figure{
            margin-bottom:30px;
          }
        }
        .after{
          position:relative;
          margin-top:94px;
          &:before{
            content:"";
            display:block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 29px 0 29px;
            border-color: #4189cc transparent transparent transparent;
            position:absolute;
            top:-60px;
            left:0;
            right:0;
            margin:auto;
          }
        }
      }
    }
  }

  /*
  射出成形とは
  -------------------------------------*/
  #PageReference.pageIndex {
    #MainImg{
      img{
        width:100%;
        height:auto;
      }
    }
    #Container{
      border-bottom:1px solid #e3e3e3;
    }
    #Main{
      #MainTilte{
        background-color:#fff;
        margin-bottom: 14px;
        height:213px;
        #MainTilteInner{
          p{
            color:#004ea2;
            margin-bottom:14px;
          }
          h2{
            margin:0;
            font-size:40px;
            color:#000;
            font-weight:700;
            span{
              font-size:14px;
              color:#888;
              margin:10px 0 0;
              font-weight:500;
              letter-spacing: 1px;
            }
          }
        }
      }
      #ContBox01{
        >p{
          text-align:center;
        }
        ul{
          font-size:0;
          letter-spacing:normal;
          margin:34px 0 200px;
          li{
            width:500px;
            display:inline-block;
            vertical-align:top;
            border-bottom:1px solid #212121;
            &:nth-child(odd){
              border-right:1px solid #212121;
            }
            &:last-child,&:nth-last-child(2):nth-child(odd){
              border-bottom:none;
            }
            a{
              background-color:#000;
              color:#fff;
              text-decoration:none;
              font-size:16px;
              display:block;
              padding:18px 55px 16px 15px;
              background-image:url(../img/img_arrow02.png);
              background-repeat:no-repeat;
              background-size:26px auto;
              background-position:center right 15px;
            }
          }
        }
      }
    }
  }

  /*
  射出成型とは（詳細）
  -------------------------------------*/
  #PageReference.pageEntry {
    #MainImg{
      height:240px;
      overflow:hidden;
      position:relative;
      background: url(../img/contents/syasyutsu05_01_midashi.jpg) no-repeat center center;
      background-size: cover;
      img{
        display:none;
        width:100%;
        height:auto;
        position: absolute;
        top:50%;
        left:50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
    #Container{
      border-bottom:1px solid #e3e3e3;
    }
    #Main{
      width:740px;
      float:right;
      margin-top:135px;
      h3{
        font-size:36px;
        line-height:50px;
        font-weight:700;
        margin-bottom:50px;
      }
      .pageMove{
        margin-top:100px;
      }
    }
  }

  /*
  会社案内
  -------------------------------------*/
  #PageCompany.pageIndex {
    #MainImg{
      width:100%;
      height:840px;
      background:url(../img/contents/company_emori.jpg) no-repeat center center;
      background-size:cover;
      #MainImgInner{
        div{
          padding-top:546px;
          h2{
            display:none;
          }
          p{
            font-size:36px;
            line-height:54px;
            font-weight:700;
            letter-spacing: 1.5px;
          }
          dl{
            text-align:left;
            margin-top:20px;
            dd{
              font-size:14px;
              font-weight:700;
              display: inline-block;
              padding-top: 4px;
              vertical-align:top;
            }
            dt{
              display:inline-block;
              width:85px;
              height:23px;
              text-indent:-9999px;
              background:url(../img/name_img.png) no-repeat top left;
              background-size:85px auto;
              margin-left: 15px;
              vertical-align:middle;
            }
          }
        }
      }
    }
    #Main{
      h3{
        font-family: 'Barlow Condensed', sans-serif;
        font-weight: 500;
        font-size:50px;
        text-align:center;
        margin-top:78px;
        margin-bottom:40px;
        letter-spacing: 3px;
      }
      img{
        width:100%;
        height:auto;
      }
      .contBox{
        p{
          &+p{
            margin-top:10px;
          }
        }
        dl{
          width:100%;
          display: table;
          table-layout: fixed;
          dt,dd{
            font-size:16px;
            line-height:28px;
            display:table-cell;
            vertical-align:top;
          }
        }
      }
      #ContBox01{
        h3{
          margin-top: 43px;
        }
        div{
          width:800px;
          margin:0 auto;
        }
        ul{
          margin-top:95px;
          li{
            width:50%;
            float:left;
          }
        }
      }
      #ContBox02{
        ul{
          li{
            width:470px;
            float:left;
            &+li{
              float:right;
            }
            dl{
              padding:20px 0;
              border-bottom:1px solid #dbdbdb;
              dt{
                width:120px;
              }
            }
          }
        }
        #Gmap01{
          margin-top:60px;
          width:100%;
          height:380px;
          &+div{
            font-size:14px;
            text-align:center;
            margin-top:15px;
            a{
              &:hover{
                opacity:1;
              }
            }
          }
        }
        figure{
          margin-top:100px;
        }
      }
      #ContBox03{
        dl{
          padding:20px 0;
          background-color:#f6f6f6;
          &:nth-child(2n){
            background-color:#fff;
          }
          dt{
            width:200px;
            padding-left:30px;
          }
        }
        figure{
          margin-top:170px;
        }
      }
    }
  }


  /*
  お問い合わせ
  -------------------------------------*/
  #PageInquiry.pageIndex,
  #PageInquiry.pageConfirm,
  #PageInquiry.pageThanks {
    #Main{
      margin-bottom:260px;
      h3{
        margin-bottom:26px;
        text-align: center;
        font-weight: 700;
        font-size: 28px;
        color: #000;
      }
      #ContSubBox01{
        >p{
          text-align:center;
          &+p{
            margin-top:20px;
          }
        }
      }
      form{
        margin-top: 53px;
        dl{
          dd{
            &:last-of-type{
              input{
                &.long{
                  width:380px;
                }
                &.middle{
                  width:290px;
                }
              }
            }
          }
        }
        ul{
          li{
            #image-btn{
              background-color:#000;
            }
          }
        }
      }
    }
    #FooterContact{
      display:none;
    }
  }

  /*
  お問い合わせ（確認）
  -------------------------------------*/
  #PageInquiry.pageConfirm {
  }

  /*
  お問い合わせ（完了）
  -------------------------------------*/
  #PageInquiry.pageThanks {
  }

  /*
  プライバシーポリシー
  -------------------------------------*/
  #Page.pagePrivacy {
    #Container{
      border-bottom:1px solid #e3e3e3;
    }
    #Main{
      margin-bottom: 195px;
      h3{
        font-size:24px;
        font-weight:700;
        margin:40px 0 10px;
      }
      p{
        &+p{
          margin-top:10px;
        }
      }
      ol{
        margin-top:10px;
        li{
          font-size:16px;
          line-height:28px;
          text-indent:-1.3em;
          padding-left:1.3em;
          ol{
            margin-bottom:10px;
            li{
              ol{
                margin-top:0;
                padding-left:2em;
              }
            }
          }
        }
      }
    }
  }

@media all and (-ms-high-contrast:none){
        *::-ms-backdrop, #Main .imgLink .innerBasic{
                width: auto;
        }
        *::-ms-backdrop, #Main .productBlock ul li div{
                width: auto;
                left: 0;
                right: 0;
        }
        *::-ms-backdrop, #PageService #Main .blogList li dl dd.cat{
                line-height: 1;
                padding-top: 5px;
        } /* IE11 */
        *::-ms-backdrop, #Footer #FooterMenu {
          overflow: hidden;
        }
        *::-ms-backdrop, #FooterContact div.linkBtn{
          line-height: 1;}
        *::-ms-backdrop, .contactBlock div.linkBtn a{
                padding-top: 19px;
                height: 50px;
                line-height: 1;
        }
        *::-ms-backdrop, #Page.pageIndex #Main #ContBox05 ul {
          overflow: hidden;
        }
        *::-ms-backdrop, #PageBusiness.pageIndex #Main #ContBox01 ul {
          overflow: hidden;
        }
        *::-ms-backdrop, #Main .imgLink .txt a {
          line-height: 46px;
        }
}

@supports (-ms-ime-align: auto) {
  #Main .imgLink .innerBasic {
    width: auto;
  }

  #Page.pageIndex #Main #ContBox05 ul,
  #PageBusiness.pageIndex #Main #ContBox01 ul {
    overflow: hidden;
  }

}